export type DeviceSizes = 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL';

export type DeviceOrientations = 'PORTRAIT' | 'LANDSCAPE';

export type DeviceSizeMap = Record<DeviceSizes, string>;

export type Breakpoints = 0 | 576 | 768 | 992 | 1200 | 1400 | 1600;

export type BreakpointMap = Record<DeviceSizes, Breakpoints | number>;

export const Breakpoints: BreakpointMap = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1360,
  XXL: 1600,
};

export const DeviceSizeMediaQueries: Record<DeviceSizes, string> = {
  XS: `(max-width: ${Breakpoints.SM - 0.2}px)`,
  SM: `(min-width: ${Breakpoints.SM}px) and (max-width:  ${Breakpoints.MD - 0.2}px)`,
  MD: `(min-width: ${Breakpoints.MD}px) and (max-width: ${Breakpoints.LG - 0.2}px)`,
  LG: `(min-width: ${Breakpoints.LG}px) and (max-width: ${Breakpoints.XL - 0.2}px)`,
  XL: `(min-width: ${Breakpoints.XL}px) and (max-width: ${Breakpoints.XXL - 0.2}px)`,
  XXL: `(min-width: ${Breakpoints.XXL}px)`,
};

export type DeviceOrientationMap = Record<DeviceOrientations, string>;

export const DeviceOrientationMediaQueries: DeviceOrientationMap = {
  PORTRAIT: '(orientation: portrait)',
  LANDSCAPE: '(orientation: landscape)',
};

export interface ResponsiveInfo {
  size: DeviceSizes | undefined;
  orientation: DeviceOrientations | undefined;
}

export type BreakpointInfo = {
  min?: DeviceSizes;
  max?: DeviceSizes;
  breakpoints?: DeviceSizes[];
};
